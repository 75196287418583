/* @import url('https://fonts.googleapis.com/css2?family=Faustina:wght@400;700&family=Heebo:wght@400;700&family=Tajawal:wght@400;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,700&display=swap');

@font-face {
  font-family: SFProDisplay;
  src: local(SFProDisplay), url("./fonts/sf-pro-display.otf") format("opentype");
}

@font-face {
  font-family: SFProDisplay;
  font-weight: bold;
  src: local(SFProDisplay), url("./fonts/sf-pro-display-bold.otf") format("opentype");
}

@font-face {
  font-family: HoeflerText;
  src: local(HoeflerText), url("./fonts/hoefler-text.ttf") format("truetype");
}

@font-face {
  font-family: HoeflerTextBlack;
  src: local(HoeflerText), url("./fonts/hoefler-text-black.ttf") format("truetype");
}

@font-face {
  font-family: Satoshi;
  src: local(Satoshi), url("./fonts/Satoshi-Regular.otf") format("opentype");
}

@font-face {
  font-family: SatoshiBold;
  src: local(SatoshiBold), url("./fonts/Satoshi-Bold.otf") format("opentype");
}

@font-face {
  font-family: SatoshiBlack;
  src: local(SatoshiBlack), url("./fonts/Satoshi-Black.otf") format("opentype");
}

.ff-sf-pro {
  font-family: SFProDisplay;
}
.ff-hoefler {
  font-family: HoeflerText;
}
.ff-hoefler-black {
  font-family: HoeflerTextBlack;
}
.ff-satoshi {
  font-family: Satoshi;
}
.ff-satoshi-bold {
  font-family: SatoshiBold;
}
.ff-satoshi-black {
  font-family: SatoshiBlack;
}

body {
  margin: 0;
  font-family: Satoshi, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: SatoshiBold;
}

.fw-bolder {
  font-weight: 900 !important;
}

.compact {
  max-width: 900px;
  margin: 0 auto;
}

.main-index {
  font-size: 60px;
}

.currency-type-tag {
  display: inline-block;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  text-transform: capitalize;
  background: #5b8aff;
  color: #fff;
  font-size: 16px;
  border-radius: 50%;
}

.currency-type-tag.C {
  
}

.ctp-stats-table th {
  font-weight: 400;
  text-transform: uppercase;
  color: #6c757d;
}

.ctp-stats-table td {
  vertical-align: middle;
  padding: 20px 0;
}

.ctp-stats-table .currency-logo {
  margin-right: 5px;
}

.ctp-stats-table .currency-name {
  color: #000000;
  font-weight: bold;
}

.currency-price-details {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  /* margin-bottom: 25px; */
}
.currency-price-percent {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.currency-details-list {
  max-width: 500px;
}

.currency-details-list li {
  padding: 10px 0;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
}

.ctp-graph-container {
  height: 400px;
  position: relative;
}

.ctp-graph-container .loading-container{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background: #ffffff9e;
  padding-top: 200px
}
.ctp-data-small {
  color: #888;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
}
.ctp-change-percent {
  display: inline-block;
  padding: 0 15px;
  margin-left: 15px;
  border-left: 1px solid #CCC;
}
.ctp-index-main-row {
  min-height: 400px;
}
.navbar-brand h2 {
  margin-bottom: 0px;
  font-size: 44px;
  
}
.navbar-brand h2 span {
  font-weight: 800;
  font-size: 32px;
  text-transform: lowercase;
  letter-spacing: 2px;
  text-shadow: -1px 0px 0px #A9A9A9, 1px 0px 0px #A9A9A9, 0px -1px 0px #A9A9A9, 0px 1px 0px #A9A9A9;
  color: #E8E8E8;
  margin-left: 3px;
}
.navbar-brand {
  position: relative;
}
.logo-notification {
  position: absolute;
  left: 0px;
  bottom: -65px;
  font-size: 16px;
  padding: 8px 12px;
  background-color: #EFEFEF;
  border-radius: 4px;
}
.logo-notification .tip {
  width: 0px;
  height: 0px;
  position: absolute;
  background: transparent;
  border: 10px solid #EFEFEF;
  top: -18px;
  left: 30px;
  border-right-color: transparent;
  border-left-color: transparent;
  border-top-color: transparent;
}
.g2-tooltip-value, .g2-tooltip-name, .g2-tooltip-title {
  font-size: 18px;
}

/* About */
.about-banner {
  position: relative;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./images/skyscrapers-construction.jpg');
  min-height: 400px;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* .about-banner:after {
  content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000000 ;
    opacity: .6; 
} */

.about-banner div {
  color: #FFF;
}

.about-banner h1 {
  font-family: SatoshiBlack;
  font-size: 60px;
}

.btn.nav-link:focus, .btn.nav-link:hover {
  color: var(--bs-btn-hover-color) !important;
}

.currency-action:hover {
  cursor: pointer;
  color: #0d6efd;
}


@media (min-width: 768px) {
  
}